import * as React from 'react';
import styled from 'styled-components';
import { hashCode } from '../../../helpers/hashCode';

import { ImageLoader } from '../ImageLoader/ImageLoader';
import { Spinner } from '../Spinner/Spinner';

const isNil = require('lodash/isNil');

const defaultColors = [
  '#d73d32',
  '#7e3794',
  '#4285f4',
  '#67ae3f',
  '#d61a7f',
  '#ff4080',
];

function getContainerStyle(size: number) {
  return {
    borderRadius: size,
    width: size,
    height: size,
    minWidth: size,
    minHeight: size,
  };
}

function getInitialsStyle(color: string) {
  return {
    backgroundColor: color,
  };
}

/** UserPictureProps */
export interface UserPictureProps {
  /** not used */
  children?: React.ReactNode;
  /** url to picture */
  url?: string;
  /** user initials - will determine color of background */
  initials?: string;
  /** size in pixels - will calculate font size of initials */
  size: number;
}

function isValidUrl(url?: string) {
  if (!url) {
    return false;
  }

  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}

const Initials = styled.div`
  width: 100%;
  height: 100%;
  vertical-align: middle;
  color: #fff;
  font-size: 1.3em;
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  .imageloader, img {
      width: 100%;
      height: 100%;
    }

  transition: width .5s, height .5s;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/** User picture with fallback to initials. */
export class UserPicture extends React.Component<UserPictureProps, {}> {

  renderInitials({ size, initials }) {
    if (isNil(initials)) {
      return null;
    }
    const fontSize = Math.ceil(size / 2.5);
    return (
      <Initials
        style={{ ...getInitialsStyle(defaultColors[hashCode(initials) % defaultColors.length]) }}
      >
        <svg height="100%" width="100%">
          <text
            textAnchor="middle"
            x="50%"
            y="50%"
            dy="0.30em"
            fontSize={fontSize}
            fill="#fff"
          >
            {initials}
          </text>
        </svg>
      </Initials>
    );
  }

  renderSpinner() {
    return (
      <SpinnerContainer>
        <Spinner/>
      </SpinnerContainer>
    );
  }

  render() {
    const { url, size } = this.props;

    return (
      <Container
        style={getContainerStyle(size)}
      >
        {url && isValidUrl(url) ? (
          <ImageLoader
            useDiv
            src={url}
            preloader={this.renderSpinner}
          >
            {this.renderInitials({size: this.props.size, initials: this.props.initials})}
          </ImageLoader>
        ) : this.renderInitials({size: this.props.size, initials: this.props.initials})}
      </Container>
    );
  }
}
