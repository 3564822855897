import { styled } from '../../../../components/Layout/ThemeProvider/ThemeProvider';

export const AsideSectionTitle = styled.div`
  font-family: Quicksand, serif;
  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) => theme.blogColors.fontPrimary5};
  margin-bottom: .5rem;

  font-size: 1.5rem;

  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    font-size: 2.2rem;
  }
`;
