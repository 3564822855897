import * as React from 'react';
import { styled } from '../../../../components/Layout/ThemeProvider/ThemeProvider';
import { AsideSectionTitle } from './AsideSectionTitle';

type Props = {
  title?: string;
  content: React.ReactNode;
  separator?: boolean;
};

const Container = styled.div`
  position: relative;
  z-index: 0;

  margin: 2rem 1.5rem;
  width: 32.8rem;

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    width: 36.3rem;
  }
  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    width: 54.6rem;
  }
`;

const ContentContainer = styled.div`
  padding: 1rem 0;
`;

const SectionSeparator = styled.hr`
  margin: 0;
  width: 100%;
  height: .1rem;
  background: ${({ theme }) => theme.blogColors.primary3};
  border: none;

  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    margin-top: 1rem;
  }
`;

const Title = styled(AsideSectionTitle)`
  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    margin-top: 3.2rem;
  }
`;

export const AsideSection: React.FC<Props> = ({ title, content, separator }) => (
  <Container>
    {title && (
      <Title>
        {title}
      </Title>
    )}
    <ContentContainer>
      {content}
    </ContentContainer>
    {separator !== false && <SectionSeparator/>}
  </Container>
);
