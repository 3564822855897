import * as React from 'react';

const Status = {
  PENDING: 'pending',
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
};

type ImageLoaderProps = {
  wrapper?:((...props) => any);

  className?: string;
  style?: object;

  preloader(props?: any): any;

  src: string;

  onLoad?:((...props) => any);
  onError?:((...props) => any);

  imgProps?: object;
  useDiv?: boolean;
};

export class ImageLoader extends React.Component<ImageLoaderProps, any> {

  img: HTMLImageElement | undefined;

  static defaultProps = {
    wrapper: (wrapperProps, img) => {
      return <div {...wrapperProps}>{img}</div>;
    },
    useDiv: false,
  };

  static renderImageAsBackgroundStyle = {
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    height: '100%',
  };

  constructor(props) {
    super(props);
    this.state = { status: props.src ? Status.LOADING : Status.PENDING };
  }

  componentDidMount() {
    if (this.state.status === Status.LOADING) {
      this.createLoader();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.src !== nextProps.src) {
      this.setState({
        status: nextProps.src ? Status.LOADING : Status.PENDING,
      });
    }
  }

  componentDidUpdate() {
    if (this.state.status === Status.LOADING && !this.img) {
      this.createLoader();
    }
  }

  componentWillUnmount() {
    this.destroyLoader();
  }

  getClassName = () => {
    let className = `imageloader ${this.state.status}`;
    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }
    return className;
  };

  createLoader = () => {
    this.destroyLoader();  // We can only have one loader at a time.

    this.img = new Image();
    this.img.onload = this.handleLoad;
    this.img.onerror = this.handleError;
    this.img.src = this.props.src;
  };

  destroyLoader = () => {
    if (this.img) {
      this.img.onload = null;
      delete this.img.onerror;
      this.img = undefined;
    }
  };

  handleLoad = (event) => {
    this.destroyLoader();
    this.setState({ status: Status.LOADED });

    if (this.props.onLoad) {
      this.props.onLoad(event);
    }
  };

  handleError = (error) => {
    this.destroyLoader();
    this.setState({ status: Status.FAILED });

    if (this.props.onError) {
      this.props.onError(error);
    }
  };

  renderImg = (error?: any) => {
    const { src, imgProps } = this.props;
    const props = { src };

    if (!imgProps) {
      return;
    }

    for (const k in imgProps) {
      if (imgProps.hasOwnProperty(k)) {
        props[k] = imgProps[k];
      }
    }

    return <img {...props} />;
  };

  renderImgAsBackground = () => <div
    style={{ ...ImageLoader.renderImageAsBackgroundStyle, backgroundImage: `url(${this.props.src})` }}
  />;

  render() {
    const wrapperProps = {
      className: this.getClassName(),
      style: this.props.style,
    };

    const wrapperArgs: any[] = [wrapperProps];

    switch (this.state.status) {
      case Status.LOADED:
        if (this.props.useDiv) {
          wrapperArgs.push((this.renderImgAsBackground()));
        } else {
          wrapperArgs.push(this.renderImg());
        }
        break;

      case Status.FAILED:
        if (this.props.children) {
          wrapperArgs.push(this.props.children);
        }
        break;

      default:
        if (this.props.preloader) {
          wrapperArgs.push(this.props.preloader());
        }
        break;
    }

    if (!this.props.wrapper) {
      return null;
    }

    return this.props.wrapper(...wrapperArgs);
  }
}
