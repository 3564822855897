import * as React from 'react';
import { calculateScrollPosition } from './calculateScrollPosition';

export const useGetDocumentScrollPercent = () => {
  const [progressBarPercent, setProgressBarPercent] = React.useState<number>(40);

  React.useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(() => {
        const scrollPosition = calculateScrollPosition();
        setProgressBarPercent(scrollPosition);
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return progressBarPercent;
};
