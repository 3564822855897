import { styled } from '../../../../components/Layout/ThemeProvider/ThemeProvider';

export const Description = styled.div`
  font-family: ${({ theme: { fonts } }) => fonts.lato}, sans-serif;
  font-style: normal;
  font-weight: normal;

  font-size: 2.4rem;
  line-height: 3.3rem;

  margin-top: 2.4rem;

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    font-size: 3.2rem;
    line-height: 4.7rem;

    margin-top: 4rem;
  }

  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    font-size: 3.8rem;
    line-height: 5.2rem;
  }
`;
