import { styled } from '../../../../components/Layout/ThemeProvider/ThemeProvider';

export const ArticleHeading = styled.h1`
  font-family: ${({ theme: { fonts } }) => fonts.merriweather};
  font-style: normal;
  font-weight: bold;

  font-size: 2.4rem;
  line-height: 3.3rem;
  margin-bottom: 2.4rem;

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    font-size: 3.8rem;
    line-height: 5.2rem;

    margin-bottom: 4rem;
  }
`;
