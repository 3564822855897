import * as React from 'react';
import { styled, Theme } from '../../../components/Layout/ThemeProvider/ThemeProvider';
import { ThemeContext } from 'styled-components';
import { Header } from './Header/Header';
import { HeaderScrollMargin } from './Header/HeaderScrollMargin';
import { useGetDocumentScrollPercent } from './Header/utils/useGetDocumentScrollPercent';

type Props = {
  titleText?: string;
  title?: React.ReactNode;
  content?: React.ReactNode;
  description?: React.ReactNode;
  authorAndDateSection?: React.ReactNode;
  localLinksSection?: React.ReactNode;
  postTypesSection?: React.ReactNode;
  topicsSection?: React.ReactNode;
  promo1?: React.ReactNode;
  promo2?: React.ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  position: relative;
  z-index: 0;

  .anchor.before {
      bottom: 0;
      top: auto;
  }
`;

const LeftColumn = styled.div`
  width: 69rem;

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    margin-left: 6rem;
    margin-top: 14rem;
  }

  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    margin-left: 25rem;
    margin-top: 17rem;
  }
`;

const RightColumn = styled.div`
  width: 46rem;

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    margin-left: 9rem;
    margin-top: 10.5rem;
  }

  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    margin-left: 17rem;
    margin-top: 15rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8rem;
  flex: 1;
  
  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    display: flex;
    align-items: initial;
    flex-direction: row;
    margin-top: 0;
    flex: initial;
  }
`;

const SingleColumn = styled.div`
  margin: 0 2rem;
`;

export const Layout: React.FC<Props> = (props) => {
  const {
    title, titleText, content, description,
    authorAndDateSection, localLinksSection, postTypesSection, topicsSection,
    promo1, promo2,
  } = props;
  const { colors: { secondary15, secondary16 }, queryBreakpoints } = React.useContext<Theme>(ThemeContext);
  const progressBarPercent = useGetDocumentScrollPercent();

  return (
    <Container>
      <Header
        progressBar={{
          percent: progressBarPercent,
          strokeColor: secondary15,
          trailColor: secondary16,
        }}
        label={titleText}
      />
      <HeaderScrollMargin/>
      <ContentContainer>
        {queryBreakpoints.small || queryBreakpoints.mobile || queryBreakpoints.tablet ? (
          <>
            {authorAndDateSection}
            {title}
            {promo1}
            <SingleColumn>
              {description}
            </SingleColumn>
            {localLinksSection}
            <SingleColumn>
              {content}
            </SingleColumn>
            {postTypesSection}
            {topicsSection}
            <SingleColumn>
              {promo2}
            </SingleColumn>
          </>
        ) : queryBreakpoints.laptop || queryBreakpoints.desktop ? (
          <>
            <LeftColumn>
              {title}
              {promo1}
              {description}
              {content}
            </LeftColumn>
            <RightColumn>
              {authorAndDateSection}
              {postTypesSection}
              {topicsSection}
              {promo2}
              {localLinksSection}
            </RightColumn>
          </>
        ) : null}
      </ContentContainer>
    </Container>
  );
};
