import { getDocHeight } from './getDocHeight';

export const calculateScrollPosition = (): number => {
  const scrollTop = window.pageYOffset;
  const winHeight = window.innerHeight;
  const docHeight = getDocHeight();

  const totalDocScrollLength = docHeight - winHeight;

  return Math.floor(scrollTop / totalDocScrollLength * 100);
};
