import { Theme } from '../../../../components/Layout/ThemeProvider/ThemeProvider';
import { ArticleHeading } from '../../Elements/ArticleHeading/ArticleHeading';
import { AsideSection } from '../../Elements/AsideSection/AsideSection';
import { AuthorAndDateSection } from '../../Elements/AsideSection/content/AuthorAndDateSection';
import { LocalLinksSection } from '../../Elements/AsideSection/content/LocalLinksSection';
import { PostTypesSection } from '../../Elements/AsideSection/content/PostTypesSection';
import { TopicsSection } from '../../Elements/AsideSection/content/TopicsSection';
import { Description } from '../../Elements/Description/Description';
import { Section } from '../../Elements/Section/Section';
import { Layout } from '../Layout';
import * as React from 'react';
import { ThemeContext } from 'styled-components';
import { Promo1Large, Promo1Small, Promo2Large, Promo2Medium, Promo2Small } from './components/Banners/Banners';

type Props = {
  titleText: string;
  content: string;
  description: string;
  authorName: string;
  createdAt: string;
  links: { label: string; slug: string; }[];
  tags: {
    label: string;
  }[];
  types: {
    slug?: string;
    label: string;
    count: number;
  }[];
  disableTopics: boolean;
  disableTypes: boolean;
};

export const ArticleTemplate = (props: Props) => {
  const { currentBreakpoint } = React.useContext<Theme>(ThemeContext);

  return (
    <Layout
      titleText={props.titleText}
      title={(
        <ArticleHeading>{`${props.titleText}`}</ArticleHeading>
      )}
      content={(
        <Section>
          {props.content}
        </Section>
      )}
      description={(
        props.description && (
          <Description>
            {props.description}
          </Description>
        )
      )}
      authorAndDateSection={(
        <AsideSection
          content={(
            <AuthorAndDateSection
              authorName={props.authorName}
              createdAt={props.createdAt}
            />
          )}
        />
      )}
      localLinksSection={(
        props.links.length !== 0
          ? (
            <AsideSection
              title="Spis treści artykułu"
              content={(
                <LocalLinksSection
                  links={props.links}
                />
              )}
              separator={false}
            />
          )
          : null
      )}
      postTypesSection={(
        props.disableTypes ? null : (
          <AsideSection
            title="Typy artykułów"
            content={(
              <PostTypesSection
                types={props.types}
              />
            )}
            separator={false}
          />
        )
      )}
      topicsSection={(
        props.disableTopics ? null : (
          <AsideSection
            title="Tematy tego artykułu"
            content={(
              <TopicsSection
                tags={props.tags}
              />
            )}
          />
        )
      )}
      promo1={(currentBreakpoint === 'desktop' || currentBreakpoint === 'laptop')
        ? <Promo1Large/>
        : <Promo1Small/>}
      promo2={currentBreakpoint === 'desktop'
        ? <Promo2Large/>
        : (currentBreakpoint === 'laptop'
          ? <Promo2Medium/>
          : <Promo2Small/>)}
    />
  );
};
