import { sanitize } from 'dompurify';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  background-color: transparent;
  padding-top: 1.8rem;
  padding-bottom: 4.5rem;

  @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.laptop}) {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }

  > h2 {
    padding: 0;
    margin-top: 2rem;
    margin-bottom: .8rem;
    font-family: ${({ theme: { fonts } }) => fonts.lato}, sans-serif;
    font-weight: 800;
    font-size: 1.8rem;
    color: ${({ theme: { blogColors } }) => blogColors.fontPrimary6};
    line-height: 146.5%;

    @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.laptop}) {
      margin: 3rem 0;
      font-size: 2.2rem;
    }
  }

  > p {
    font-family: ${({ theme: { fonts } }) => fonts.lato}, sans-serif;
    font-size: 1.8rem;
    color: ${({ theme: { blogColors } }) => blogColors.fontPrimary7};
    line-height: 120%;

    @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.laptop}) {
      font-size: 2.2rem;
    }
  }

  > ol > li,
  > ul > li {
    margin-bottom: .3rem;
    font-family: ${({ theme: { fonts } }) => fonts.lato}, sans-serif;
    font-size: 1.8rem;
    color: ${({ theme: { blogColors } }) => blogColors.fontPrimary7};
    line-height: 120%;

    @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.laptop}) {
      font-size: 2.2rem;
    }
  }
  
  table {
    border: .1rem solid ${({ theme: { blogColors } }) => blogColors.primary3};
    background-color: ${({ theme: { blogColors } }) => blogColors.lightblue1};
    color: ${({ theme: { blogColors } }) => blogColors.fontPrimary6};
    border-collapse: collapse;
    font-family: ${({ theme: { fonts } }) => fonts.lato}, sans-serif;
    font-style: normal;
    
    td, th {
      text-align: left;
      vertical-align: top;
    
      border: .1rem solid ${({ theme: { blogColors } }) => blogColors.primary3};
      padding: 1rem .7rem;
      
      @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.laptop}) {
        padding: 1.5rem 2rem;
      }
    }
    
    td {
      font-size: .8rem;
      line-height: 120.5%;
      
      @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.laptop}) {
        font-size: 1.8rem;
      } 
    }

    th {
      font-size: .9rem;
      line-height: 120.5%;
      font-weight: bold;

      @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.laptop}) {
        font-size: 2rem;
      } 
    }
  }
`;

type Props = {
  children: string;
};

export const Section: React.FC<Props> = props => (
  // <Container dangerouslySetInnerHTML={{ __html: sanitize(props.children) }}/>
  <Container dangerouslySetInnerHTML={{ __html: props.children }}/>
);
