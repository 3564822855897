import { ThemeProvider } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';
import { ArticleTemplate } from 'components/src/porady-ksiegowe.fakturuj.to/components/Layout/ArticleTemplate/ArticleTemplate';
import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

const Article = ({ article }) => {
  const { description, title, author, date, topics, types, disableTopics, disableTypes } = article.frontmatter;
  const links = article.headings
    .filter(({ id }) => !!id)
    .map(({ id, value }) => ({ label: value, slug: id }));

  return (
    <>
      <Helmet titleTemplate="%s | Blog">
        <title>{`${title}`}</title>
        <meta
          name="description"
          content={`${description}`}
        />
      </Helmet>
      <ArticleTemplate
        titleText={title}
        content={article.html}
        description={description}
        authorName={author}
        createdAt={date}
        links={links}
        tags={topics}
        types={types}
        disableTopics={disableTopics}
        disableTypes={disableTypes}
      />
    </>
  );
};

export default ({ data: { markdownRemark: article } }) => {
  return (
    <ThemeProvider
      render={() => (
        <Article
          article={article}
        />
      )}
    />
  );
};

export const pageQuery = graphql`
    query BlogPostByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            headings {
                id
                value
            }
            frontmatter {
                date(formatString: "DD.MM.YYYY")
                title
                description
                author
                disableTopics
                disableTypes
                topics {
                    label
                }
                types {
                    label
                    count
                }
            }
        }
    }
`;
