import { createGlobalStyle } from 'styled-components';

export const HeaderScrollMargin = createGlobalStyle`
  :target::before {
    content: "";
    display: block;
    position: relative;
    bottom: 0;
    width: 0;
    height: 8rem;
    margin-top: -8rem;
  }
`;
