import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { UserPicture } from '../../../../../components/Elements/UserPicture/UserPicture';
import { Theme } from '../../../../../components/Layout/ThemeProvider/ThemeProvider';
import { getInitialsFromName } from '../../../../../helpers/getInitialsFromName';
import { AsideSectionTitle } from '../AsideSectionTitle';

type Props = {
  avatarUrl?: string;
  authorName: string;
  createdAt?: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: .4rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Value = styled.div`
  font-family: Quicksand, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;

  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    margin-top: .4rem;
    font-size: 2.2rem;
  }
`;

export const AuthorAndDateSection: React.FC<Props> = ({ avatarUrl, authorName, createdAt }) => {
  const { currentBreakpoint } = React.useContext<Theme>(ThemeContext);

  return (
    <Container>
      <Column
        style={{ minWidth: '5rem' }}
      >
        <UserPicture
          url={avatarUrl}
          initials={getInitialsFromName(authorName)}
          size={{ laptop: 53, desktop: 80 }[currentBreakpoint] ?? 48}
        />
      </Column>
      <Column
        style={{
          desktop: {
            marginTop: '.7rem',
            marginLeft: '1.9rem',
            width: '25.9rem',
          },
          laptop: {
            marginTop: '.5rem',
            marginLeft: '1.3rem',
            width: '17rem',
          },
        }[currentBreakpoint] ?? {
          marginTop: 0,
          marginLeft: '1rem',
          width: '12rem',
        }}
      >
        <AsideSectionTitle>
          Autor
        </AsideSectionTitle>
        <Value>
          {authorName}
        </Value>
      </Column>
      {createdAt && (
        <Column
          style={{
            desktop: {
              marginTop: '.7rem',
              marginLeft: '.5rem',
              marginRight: '2rem',
              flex: 1,
            },
            laptop: {
              marginTop: '.5rem',
              marginLeft: '.5rem',
              marginRight: '2rem',
              flex: 1,
            },
          }[currentBreakpoint] ?? {
            marginTop: 0,
            marginLeft: '1rem',
            marginRight: '2rem',
            flex: 1,
          }}
        >
          <AsideSectionTitle>
            Data
          </AsideSectionTitle>
          <Value>
            {createdAt}
          </Value>
        </Column>
      )}
    </Container>
  );
};
